import React, { useState } from 'react'
import { Spin, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { getCurrentTimestamp, getFileMetadata, getOutputURL, getSharedLink } from '../../../services/dropbox';
import { fetchAccessToken, generateIMSToken, getImageUrl, getInputURL, getUserData, handleDownloadFromDropbox, handleUserCredits, performBackgroundRemoval } from '../../../services/API';
import Result from '../../Result';
import { withProfile } from '../../../contexts/profileContext';
import { notificationModal } from '../../../utils/functions';
import useStorage from '../../../use/useStorage';
import { useNavigate } from 'react-router-dom'

const { Dragger } = Upload;

const HeroArea = ({ isActive, setValidatedProfile, outOfCredits }) => {
    const [cache] = useStorage({
        profile: null,
    });

    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [processComplete, setProcessComplete] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const props = {
        name: 'file',
        multiple: false,
        accept: '.png,.jpg,.jpeg',
        beforeUpload() {
            return false;
        },
        onChange: (e) => handleBackgroundRemoval(e.file),
        showUploadList: false,
    };

    const delay = async (x) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, x);
        });
    }

    const handleBackgroundRemoval = async (imageData) => {
        if (!isActive()) {
            return navigate("/login");
        }
        console.log("hasCredits", outOfCredits(), getCurrentTimestamp());
        if (outOfCredits()) {
            const title = <b className='text-danger'>Out of Credits</b>;
            const content = <div className='text-center'>You have run out of credits. Please purchase more credits to continue using this feature.</div>
            return notificationModal(title, content, 416);
        }
        console.log("imageData: ", imageData, getCurrentTimestamp());
        setProcessing(true);
        setLoadingText('Image Recived ...');
        try {
            const access_token = await fetchAccessToken();
            setLoadingText('dropbox token generated ...');
            const inputURL = await getInputURL(imageData);
            setLoadingText('Image Uploaded ...');
            const outputURL = await getOutputURL(access_token);
            setLoadingText('Token generating ...');
            const token = await generateIMSToken();
            setLoadingText('Token generated ...');
            await delay(1000);
            setLoadingText('Removing Background ...');
            const result = await performBackgroundRemoval(inputURL, outputURL, token);

            let status = 'running';
            let jobResult;

            while (status === 'running' || status === 'pending' || status === 'starting') {
                console.log('delaying while checking', getCurrentTimestamp());
                await delay(3000);
                let jobReq = await fetch(result['_links']['self']['href'], {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-api-key': process.env.REACT_APP_ADOBE_API_KEY
                    }
                })
                jobResult = await jobReq.json();
                status = jobResult['status'];
            }

            console.log('Final result', jobResult, getCurrentTimestamp());
            console.log('Background removal result:', result, getCurrentTimestamp());

            const filePath = await getFileMetadata(access_token);

            if (filePath) {
                const sharedLink = await getSharedLink(access_token, filePath);
                console.log("SharedLink: ", sharedLink, getCurrentTimestamp());
                if (sharedLink) {
                    await handleDownloadFromDropbox(sharedLink);
                    const result = getImageUrl();
                    setProcessComplete(true);
                    setImageUrl(result);
                    const user = cache.profile
                    await handleUserCredits(user?.user_id);
                    const response = await getUserData(user?.user_id);
                    setValidatedProfile(response);
                }
            }
            setLoadingText('Background removed ...');
            setProcessing(false);
            setLoadingText('');
        } catch (error) {
            console.error('Error performing background removal:', error);
            setProcessing(false);
        }
    }

    return (
        <Spin spinning={processing} tip={loadingText}>
            <div className="header-banner">
                <div className="bg-light1 positiona"> <img src="assets/img/banner-two-bg-light1.svg" alt="" /> </div>
                <div className="bannerline positiona"> <img src="assets/img/bannerline1.svg" alt="" /> </div>
                <div className="bannerline bannerline2 positiona"> <img src="assets/img/bannerline2.svg" alt="" /> </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 directionr">
                            <div className="directionryt positiona"> <img src="assets/img/directionryt.svg" alt="" /> </div>
                            <div className="headingtxt">
                                <h1>Remove background from images in <span>a click</span></h1>
                                <p>DeleteBG is an AI-powered image processing tool that frees you up from repetitive tasks for more
                                    valuable creative work. </p>
                            </div>
                            <div className="uploadimage text-center" title="Select photos remove background">
                                <div className="browse-input m-auto">
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined style={{ fontSize: 80 }} />
                                        </p>
                                        <p className="ant-upload-text text-light">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint text-light">JPEG, JPG, PNG or WEBP • 30MB max</p>
                                    </Dragger>
                                </div>
                            </div>
                            <div className="trydiv d-flex align-items-center">
                                <h5>No Image? <span>Try one of this:</span> </h5>
                                <div className="sampleimgs"> <a href="#"> <img src="assets/img/sample1.svg" alt="" /> </a> <a href="#"> <img src="assets/img/sample2.svg" alt="" /> </a> <a href="#"> <img src="assets/img/sample3.svg" alt="" /> </a> <a href="#"> <img src="assets/img/sample4.svg" alt="" /> </a> </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                {processComplete ? <Result imageUrl={imageUrl} /> : <div className="bgryt"> <img src="assets/img/bgryt.svg" alt="" /> </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default withProfile(HeroArea);