import React from 'react';

const Result = ({ imageUrl }) => {

    const handleDownload = async () => {
        try {
            const response = await fetch(imageUrl, {
                mode: 'cors',
            });
            const blob = await response.blob();
            const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = imageName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <div className='d-flex flex-column align-items-center'>
            <img src={imageUrl} alt="result" style={{ maxWidth: '100%', height: 'auto', width: '300px' }} />
            <button className='btn btn-primary mt-3' onClick={handleDownload}>Download Image</button>
        </div>
    );
};

export default Result;